import * as React from "react";

function SvgCat(props) {
  return (
    <svg
      id="cat"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 652.02 415.76"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {
            ".cat_svg__cls-1{fill:#1e212b}.cat_svg__cls-2{fill:#fffef9}.cat_svg__cls-3{fill:#fff5c8}.cat_svg__cls-5{fill:#fcdce1}"
          }
        </style>
      </defs>
      <g id="cat_svg__bread_colour">
        <path
          className="cat_svg__cls-1"
          d="M226.66 316a1 1 0 000-2 1 1 0 000 2zM621.46 331.58c-3-3.17-7.86-.19-10.37 2.09-3.35 3-5.76 6.73-5.78 11.38a10.52 10.52 0 001.51 6 4.67 4.67 0 005 1.77 13.94 13.94 0 005.5-2.95 16.89 16.89 0 004-4.54 15.28 15.28 0 002.11-7c.1-2.29-.28-5.05-2-6.71-.93-.89-2.34.52-1.41 1.41 1.27 1.22 1.47 3.31 1.44 5a12.9 12.9 0 01-1.17 5 15.6 15.6 0 01-6.68 6.93c-1.27.71-3.16 1.55-4.52.61s-1.68-3-1.78-4.52a12.15 12.15 0 013.63-9.44 16.82 16.82 0 014.54-3.65c1.36-.66 3.36-1.17 4.54.06.89.94 2.3-.48 1.41-1.41zM648 221.25a4.51 4.51 0 01.53.08c-.37-.06-.53-.08-.53-.08z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M649 222.42c7.76 1.9 6.94 16.76 6.94 16.76-.66 3.54-2.66 8.45-7.28 13.87 2.46 3 13.5 17.83 11.2 35.11-2.15 16.17-13.21 31.63-30.34 33.33a1.66 1.66 0 00-1.51 1.58l-.68 21.93a4.5 4.5 0 01-1.52 3.23L602.15 369a1.32 1.32 0 01-2.19-1l1.76-51.3a4.4 4.4 0 011.57-3.21L627 293.54a1.11 1.11 0 011.82.88l-.5 17.21a.91.91 0 001.06.93c4.09-.68 16.57-4.28 21.18-23.18 0 0 3.87-14.77-8-29.64-15.55 16.09-38.13 33.54-38.13 33.54C565 327.07 504.25 353 504.25 353c-32.27 16.64-68.33 1.69-68.33 1.69-4.25-1.42-6.94 3.68-14.88 36.61s7.96 44.84 7.96 44.84c32.89 19.11 78.26 5 78.26 5 72.48-24.32 142.63-96.55 148.93-102.36s6.77-60 7.9-87.54c.91-23.51-11.49-28.01-15.09-28.82z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M437.34 355.55l-10.09-5.29-6.19-6.16-45.79-6.1-26.8-4.14-24.93-5.35-19.64-4.33-16.61-2.89-26.94-2.41h-7.79l-15.89-16-106.35-3.8-19.49-5-17.49-3-29.9-3.5-25.26-2.74-13.31-2.13-3.44-2.18-.73-3.13-2.59 1.6s-3.95 2.63-3.84 12.81.78 20.17.78 20.17l.18 17s-.19 5.21-1 15.11l.24 10.19s.74 6.37 6 9.37l36.64 7 96 18 69.31 12.24 44.58 7.28 62.77 9.29 49.93 6.38 38 4.16 17.42 10z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M629.4 228.27l6.8-7.34 12.33 14.45-.45 28.66-14.68 27.34-18.56.22.44-15.12 14.12-48.21z"
        />
        <path
          className="cat_svg__cls-1"
          d="M179 203.67c1.42-1.1-46.67 19.75-57.33 65 0 0-3.34 10.62 6 24 0 0 3.9 54.82 16.78 43.74 0 0-10.56 5.92-6.8-41.09l1.94-23-.4-3.2 2-.37S147 228.33 179 203.67z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <path
        className="cat_svg__cls-2"
        d="M153.21 310.54s13 15.88 38.79 14.84 33.33-8.65 44.33-20.51l-.42 17.91s-.85 14.71 4 19.24 8.36-1.73 8.91-4.13 1-4.88 2.77-20.77 5.4-19.68 20.29-29.9 49.89-14.11 75.78-27c0 0 41.66-17.44 48.55-24s13.46-10.77 13.62-18.72c0 0-1.28-5.06-17.51.72 0 0-14.33 6.56-19.77-7.89s-16.36-30.66-35.12-38.11-44.88-9.22-85.33-1.33-63.76 24.84-75.1 34.64-27.67 30.47-31.51 45.26-4.26 18.62-4.26 18.62l-2 1.27v4.08s-3.33 22.1-3.09 31.73-.36 23.51 6.16 28.73c0 0 7 1.17 7.44-8.3-.03 0 1.8-15.92 3.47-16.38z"
        transform="translate(-13.14 -30.4)"
        id="cat_svg__body_one_colour"
      />
      <path
        className="cat_svg__cls-2"
        d="M153.21 310.54s11.79 17 43 14.68 39.46-19.65 39.46-19.65-3.17 12.7-3.61 19-2.29 20.85 4.37 22.86 11.78-18.1 12.67-22.76c0 0 6.67-20.19 10.11-25.14.53-.77 7.53-9.28 7.53-9.28s11.36-9.14 50.81-18.92a348.25 348.25 0 0045.77-18s23.11-9.33 38.23-21.22c0 0 9.68-8.21 8.28-14.61 0 0-2.51-4.39-16.73.72s-19.33-2.55-22.22-10.78a63.21 63.21 0 00-28.67-33.11c-17.33-9.44-45.89-11.55-85.78-4s-53.87 17.45-69.76 27.67-34.11 32-38.79 46.05a194.71 194.71 0 00-6.65 25.36l-1.84.49.72 5.1s-6.27 23-6.08 35c0 0-2.95 20.85 2.51 23.33 0 0 4.68 4.15 9.57-8.62l7.1-14.13"
        transform="translate(-13.14 -30.4)"
        id="cat_svg__body_two_colour"
      />
      <path
        className="cat_svg__cls-2"
        d="M152.5 310s14.06 17.07 40.06 15.42 34.15-9.47 42.85-19.23l-4.8 19.34-4.39 10.84s-3.76 10.42 1.48 10.88 9.56.36 15.15-9.73c0 0 6-11 9-19.37 0 0 8.86-21.72 20.7-29.83 0 0 3.94-5.17 25.83-11.61s37.16-9.74 61.5-21.56c0 0 23.72-10.28 31.55-16.05 0 0 11.55-7.25 16.22-14.21 0 0 9.78-11.68-7.22-8.63 0 0-22.5 9.78-27.33-3.83 0 0-9.55-34.17-40.33-41.78 0 0-27.34-13.72-101.89 5.5 0 0-45.15 13.28-69.85 46.25 0 0-17.69 21.91-19.81 47.05l-1.84.49.42 5.44-5.34 28A140.86 140.86 0 01128 320.1s-7.56 17.53 9.49 10.22A48.36 48.36 0 00152.5 310z"
        transform="translate(-13.14 -30.4)"
        id="cat_svg__body_three_colour"
      />
      <path
        className="cat_svg__cls-3"
        d="M340.33 73.33L179 204s41.29-1.11 71.87 48.67a157.62 157.62 0 0110.63 30.66s1.5 5.17 5.25 6.92c0 0 2.25 1.87 8 2a275 275 0 0142.65 5.31c24.23 4.91 62.12-1.14 78.56-20s7.67-46.25 7.67-46.25 6.46-5.33 1.9-15.66l8.32-106.9-73.52-35.42"
        transform="translate(-13.14 -30.4)"
        id="cat_svg__light"
      />
      <g id="cat_svg__bread_lines">
        <path
          className="cat_svg__cls-1"
          d="M19.46 278.75a107.24 107.24 0 0114.85-8.55c5.37-2.65 10.77-5.22 16.16-7.81q16.16-7.74 32.37-15.39 32.53-15.34 65.29-30.24 18.59-8.44 37.22-16.75c1.17-.52.16-2.24-1-1.72q-33 14.67-65.71 29.8t-65.18 30.66q-9 4.33-18.07 8.7A118.14 118.14 0 0018.45 277c-1 .74 0 2.47 1 1.72z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M18.93 276.71c-3.65 3.15-4.89 8.18-5.44 12.77-.67 5.61-.16 11.19.23 16.79q.76 11 .53 22.08-.12 5.46-.48 10.91c-.25 3.73-.72 7.46-.62 11.19a24.32 24.32 0 001.64 8.77 10.1 10.1 0 005.7 5.41c1.18.49 1.7-1.44.53-1.93-5.15-2.15-5.89-8.27-5.88-13.21 0-3.33.39-6.66.61-10s.39-6.77.47-10.16q.27-10.43-.36-20.85c-.32-5.38-.93-10.73-.62-16.13.28-4.95 1.14-10.82 5.1-14.25 1-.84-.44-2.25-1.41-1.41z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M19.74 277.15c-.76 2.77 1.25 4.88 3.6 6 3.13 1.51 6.79 1.65 10.19 2.1 8.23 1.1 16.49 2 24.75 2.82 16 1.64 32.14 3.17 48 6.19a223.3 223.3 0 0127.34 6.92c1.23.39 1.76-1.54.53-1.93a258.16 258.16 0 00-45.71-9.84c-15.45-2.05-31-3.29-46.49-5.11-4.54-.53-9.08-1.1-13.6-1.77-2.27-.34-7.58-1.57-6.69-4.86a1 1 0 00-1.92-.53zM252.56 319.75a242.94 242.94 0 0134.29 2.47c11.22 1.63 22.32 4 33.35 6.53 10.63 2.47 21.21 4.94 32 6.77s21.71 3.33 32.6 4.75c12 1.57 24 3 36 4.79 1.26.19 1.8-1.74.53-1.92-21.63-3.2-43.39-5.44-65-8.95-10.73-1.74-21.3-4-31.88-6.49-11.2-2.65-22.46-5.13-33.84-6.91a247.7 247.7 0 00-38.07-3 1 1 0 000 2zM20.49 364.63Q59.24 372 98 379.39t77.37 14.28q38.49 6.9 77.07 13.19 38.81 6.27 77.74 11.66t77.76 9.48c3.24.35 6.48.68 9.73 1 1.28.13 1.27-1.87 0-2q-39-4-77.94-9.19t-77.91-11.46q-38.61-6.15-77.09-13t-77-14.12q-38.58-7.26-77.11-14.67L21 362.7c-1.26-.24-1.8 1.69-.53 1.93z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M642.74 256.85c-11.53 13.06-24.79 24.52-38.49 35.24s-28.1 21.13-42.77 30.75c-7.47 4.91-15.1 9.57-23 13.79S522.29 344.45 514 348c-15.61 6.75-31.72 13.13-49.07 12a109.63 109.63 0 01-21.52-3.55c-6.56-1.8-13.18-4.49-18.22-9.19a24.74 24.74 0 01-3.27-3.67c-.75-1-2.49 0-1.73 1 8.39 11.51 23.89 14.79 37.19 16.58a79.2 79.2 0 0024.73-.2 120.74 120.74 0 0024.46-7.66c8.32-3.41 16.67-6.9 24.77-10.81a255.63 255.63 0 0024-13.37c15.25-9.52 29.93-20.08 44.24-31S627.57 276 639.8 263q2.22-2.34 4.35-4.77c.85-1-.56-2.38-1.41-1.41zM417.16 428.86a100.17 100.17 0 0029.21 14.2 73.34 73.34 0 0014.95 2.84 136.89 136.89 0 0015.82.07 120.45 120.45 0 0033-6c11.78-3.91 23-9.34 33.94-15.15 9.3-4.94 18.7-9.89 27.63-15.49 7.22-4.54 14.17-9.52 21-14.67 14.37-10.89 28.16-22.56 41.44-34.74 5.16-4.74 10.26-9.55 15.16-14.56 2.72-2.79 5.74-5.65 7.84-9a5.47 5.47 0 00.62-1.26c.38-1.23-1.55-1.76-1.93-.53-.08.27.08-.09-.11.26s-.25.43-.39.64a16.75 16.75 0 01-1.1 1.48c-1.29 1.59-2.68 3.11-4.09 4.6-4.33 4.59-8.88 9-13.48 13.27q-19.13 18-39.83 34.18c-6.84 5.34-13.83 10.52-21.06 15.35-7.61 5.08-15.61 9.43-23.62 13.85-21.16 11.67-43.45 23.21-67.94 25.3-4.87.42-9.77.63-14.66.64a74.76 74.76 0 01-14.41-1 93.07 93.07 0 01-30-11.49c-2.38-1.43-4.71-3-7-4.52-1.06-.73-2.06 1-1 1.72zM648.84 253.3c3.7-4.91 8.56-9.42 9.25-15.89.66-6.17-2.51-12.32-7.29-16-5.21-4.06-11.79-6.05-18.1-7.64a107.26 107.26 0 00-50.35-.44c-1.26.28-.73 2.21.53 1.92a105.7 105.7 0 0144.79-.57c6.46 1.32 13.22 3 19 6.34a20.41 20.41 0 016.44 5.5 17.22 17.22 0 013 8.48c.62 7.07-5.12 12.19-9 17.33-.78 1 1 2 1.72 1z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M646.28 220.74c6.05 1.79 11.85 5.57 14.45 11.54 3.19 7.36 2.45 16 2.18 23.8q-.92 25.59-2.83 51.14c-.68 9.2-1 18.7-4.25 27.44-.45 1.21 1.49 1.73 1.93.53 2.92-7.91 3.44-16.41 4.08-24.74q1-13.4 1.79-26.8t1.25-26.82c.29-8.11 1-16.81-2-24.54-2.67-6.95-9.12-11.43-16.06-13.48a1 1 0 00-.53 1.93zM343.63 176.06c30.91 6.09 61.88 11.84 92.82 17.75q23.12 4.41 46.19 9c15.6 3.08 31.23 6.07 47 8.36a341.67 341.67 0 0053.16 4 1 1 0 000-2c-32 .38-63.65-5.28-94.92-11.4q-46.1-9-92.25-17.78-25.74-4.87-51.45-9.9c-1.25-.25-1.79 1.68-.53 1.93z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          d="M331.72 163.82s3.7 5.6 14.68 3.3l-7.34 6.57-15.75-4.93z"
          transform="translate(-13.14 -30.4)"
          fill="#231f20"
        />
      </g>
      <g id="cat_svg__cheeks">
        <path
          className="cat_svg__cls-5"
          d="M158.39 305.57s7.7 4.65 5.06 13.18c0 0-12-7.65-12.78-13.18zM218.9 319.78s-12.34 5.55-14.12-3.78c0 0-1.11-4.41 8-8.65 0 0 9.48-2.55 13.33 1.1 0 0 5.52 7.03-7.21 11.33z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <g id="cat_svg__body_one">
        <path
          className="cat_svg__cls-1"
          d="M141.27 279.56a70.3 70.3 0 007.24 25.08 36.62 36.62 0 0015.8 15.72 57.67 57.67 0 0021.24 5.7 68.43 68.43 0 0026.29-2.4 46.14 46.14 0 0021.74-14c.82-1-.58-2.4-1.42-1.42-10.52 12.49-27.19 16.71-43 16-14.34-.62-29.55-5.23-37.49-18.08-4.9-7.92-7.45-17.47-8.44-26.66-.14-1.27-2.14-1.28-2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M143.26 279.68l-1.7-5-.85-2.5c-.15-.43-.31-.86-.44-1.3 0-.1-.05-.21-.08-.31s0-.19 0 0c-.18.11-.2.14-.07.1a3.82 3.82 0 00.55-.11l.54-.09 1.11-.16a23.82 23.82 0 012.39-.2 10.26 10.26 0 014.52.71 1 1 0 001.37-.36 1 1 0 00-.36-1.37 12.63 12.63 0 00-5.63-1 26.82 26.82 0 00-2.83.27 7.72 7.72 0 00-2.71.57 2.06 2.06 0 00-.71 2.49c.28 1 .64 1.9 1 2.84l2 6c.41 1.21 2.35.69 1.93-.53zM210.18 270.3a31.7 31.7 0 013.42-2.3c.58-.35 1.17-.68 1.77-1l.91-.45c.15-.07.54-.3.71-.24s.51.85.59 1.12a7 7 0 01.23 1.7c.07 1.47.06 2.94.09 4.4a1 1 0 002 0c0-1.66 0-3.35-.13-5-.12-1.44-.56-3.61-2.16-4.11a3.43 3.43 0 00-2.49.48c-.73.36-1.46.74-2.17 1.15a34.7 34.7 0 00-4.18 2.86 1 1 0 101.41 1.41zM233.24 310.24l3.37-4a1 1 0 000-1.42 1 1 0 00-1.41 0l-3.38 4a1 1 0 001.42 1.42z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M235.09 305.54c.49 6.81-.46 13.66-.26 20.48a49.9 49.9 0 001.7 12.23 8.46 8.46 0 003.05 4.77 5.87 5.87 0 005 1.2c3.81-.89 5.09-5.18 5.75-8.53.77-3.86 1.15-7.79 1.54-11.71.8-8.05 1.19-16.48 6-23.34a41.7 41.7 0 0111.21-10.42 68 68 0 0114.14-7.13c1.2-.45.68-2.38-.53-1.93a63.7 63.7 0 00-22.09 13.29 29.7 29.7 0 00-7.09 9.86 41.62 41.62 0 00-2.64 10.69c-1 7.49-1 15.33-3 22.66-.5 1.79-1.55 3.95-3.45 4.57s-4.19-.65-5.17-2.39a18.24 18.24 0 01-1.62-5.87c-.36-2.22-.59-4.47-.74-6.71-.47-7.22.72-14.47.2-21.69-.1-1.28-2.1-1.29-2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M282.83 283.21c12.31-5.14 25.41-7.86 38.11-11.81 13-4 25.45-9.38 37.84-14.88 11.47-5.09 23.19-10 33.74-16.88a65.38 65.38 0 0013.72-11.3c2.45-2.81 8-10.5 2.55-13-2.64-1.22-6-.55-8.74 0-3.49.77-6.85 2.05-10.34 2.8-5.06 1.1-11.89.68-14.64-4.44-.61-1.14-2.34-.13-1.73 1 2.17 4 6.67 5.71 11 5.91 5.62.27 10.82-2.16 16.2-3.35a23.25 23.25 0 014.54-.6c1.15 0 2.76 0 3.6.95s0 2.94-.46 4.05a19.44 19.44 0 01-2.17 3.68c-3.48 4.72-8.5 8.46-13.31 11.71-10 6.76-21.17 11.56-32.15 16.45-12.08 5.38-24.22 10.69-36.78 14.85-13.77 4.55-28.13 7.3-41.55 12.91-1.17.49-.66 2.43.53 1.93zM152.35 310a38.58 38.58 0 00-2.83 9.2c-.55 3.27-.42 6.65-1.2 9.88-.54 2.21-1.9 5.21-4.58 5.32-2.22.09-3.24-2.49-4-4.2a23.1 23.1 0 01-1.66-6.65c-.43-3.37-.73-6.76-.88-10.16a137.58 137.58 0 01.54-18.49c.67-7.37 1.73-14.7 2.7-22 .17-1.27-1.83-1.26-2 0-1.84 13.9-3.95 27.82-3.17 41.88.32 5.79.52 12 3.14 17.32.83 1.68 2 3.74 3.94 4.19 2.63.6 5.12-1 6.51-3.15 1.86-2.92 1.9-6.68 2.18-10a37.65 37.65 0 013-12.07c.52-1.16-1.21-2.18-1.72-1zM142.09 269.43a94.64 94.64 0 0116.31-41.63 120.28 120.28 0 0133.15-32.05A161.31 161.31 0 01237 175.62a223.8 223.8 0 0124.54-5.29c8.59-1.39 17.22-2.62 25.9-3.27 17.23-1.3 34.74-.07 50.86 6.58 2.17.89 4.29 1.89 6.38 3 1.14.59 2.15-1.14 1-1.72-16-8.16-33.76-10.85-51.57-10.19a239.36 239.36 0 00-26.17 2.64c-8.6 1.27-17.17 2.74-25.62 4.83a170.49 170.49 0 00-47 18.9 126.13 126.13 0 00-35.4 31.32A98.88 98.88 0 00141 263.76c-.35 1.88-.63 3.77-.87 5.67-.16 1.28 1.84 1.27 2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M344.67 176.59a64 64 0 0124.13 27.56c1.83 4 3 8.32 5.48 12 .72 1.05 2.45.05 1.72-1-2.56-3.76-3.73-8.25-5.64-12.34a64.31 64.31 0 00-6.45-10.9 66.85 66.85 0 00-18.23-17c-1.1-.69-2.1 1-1 1.72z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <g id="cat_svg__body_two">
        <path
          className="cat_svg__cls-1"
          d="M141.27 279.56a70.3 70.3 0 007.24 25.08 36.62 36.62 0 0015.8 15.72 57.67 57.67 0 0021.24 5.7 68.43 68.43 0 0026.29-2.4 46.14 46.14 0 0021.74-14c.82-1-.58-2.4-1.42-1.42-10.52 12.49-27.19 16.71-43 16-14.34-.62-29.55-5.23-37.49-18.08-4.9-7.92-7.45-17.47-8.44-26.66-.14-1.27-2.14-1.28-2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M143.26 279.68l-1.7-5-.85-2.5c-.15-.43-.31-.86-.44-1.3 0-.1-.05-.21-.08-.31s0-.19 0 0c-.18.11-.2.14-.07.1a3.82 3.82 0 00.55-.11l.54-.09 1.11-.16a23.82 23.82 0 012.39-.2 10.26 10.26 0 014.52.71 1 1 0 001.37-.36 1 1 0 00-.36-1.37 12.63 12.63 0 00-5.63-1 26.82 26.82 0 00-2.83.27 7.72 7.72 0 00-2.71.57 2.06 2.06 0 00-.71 2.49c.28 1 .64 1.9 1 2.84l2 6c.41 1.21 2.35.69 1.93-.53zM210.18 270.3a31.7 31.7 0 013.42-2.3c.58-.35 1.17-.68 1.77-1l.91-.45c.15-.07.54-.3.71-.24s.51.85.59 1.12a7 7 0 01.23 1.7c.07 1.47.06 2.94.09 4.4a1 1 0 002 0c0-1.66 0-3.35-.13-5-.12-1.44-.56-3.61-2.16-4.11a3.43 3.43 0 00-2.49.48c-.73.36-1.46.74-2.17 1.15a34.7 34.7 0 00-4.18 2.86 1 1 0 101.41 1.41zM233.24 310.24l3.37-4a1 1 0 000-1.42 1 1 0 00-1.41 0l-3.38 4a1 1 0 001.42 1.42zM282.83 283.21c12.31-5.14 25.41-7.86 38.11-11.81 13-4 25.45-9.38 37.84-14.88 11.47-5.09 23.19-10 33.74-16.88a65.38 65.38 0 0013.72-11.3c2.45-2.81 8-10.5 2.55-13-2.64-1.22-6-.55-8.74 0-3.49.77-6.85 2.05-10.34 2.8-5.06 1.1-11.89.68-14.64-4.44-.61-1.14-2.34-.13-1.73 1 2.17 4 6.67 5.71 11 5.91 5.62.27 10.82-2.16 16.2-3.35a23.25 23.25 0 014.54-.6c1.15 0 2.76 0 3.6.95s0 2.94-.46 4.05a19.44 19.44 0 01-2.17 3.68c-3.48 4.72-8.5 8.46-13.31 11.71-10 6.76-21.17 11.56-32.15 16.45-12.08 5.38-24.22 10.69-36.78 14.85-13.77 4.55-28.13 7.3-41.55 12.91-1.17.49-.66 2.43.53 1.93z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M142.22 268.71a92.57 92.57 0 0116.23-41.21 121.57 121.57 0 0133.1-31.75A161.31 161.31 0 01237 175.62a223.8 223.8 0 0124.54-5.29c8.59-1.39 17.22-2.62 25.9-3.27 17.23-1.3 34.74-.07 50.86 6.58 2.17.89 4.29 1.89 6.38 3 1.14.59 2.15-1.14 1-1.72-16-8.16-33.76-10.85-51.57-10.19a239.36 239.36 0 00-26.17 2.64c-8.6 1.27-17.17 2.74-25.62 4.83a170.49 170.49 0 00-47 18.9 127.31 127.31 0 00-35.35 31.08A96.71 96.71 0 00141.09 263q-.53 2.82-.87 5.67c-.16 1.28 1.84 1.26 2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M344.67 176.59a64 64 0 0124.13 27.56c1.83 4 3 8.32 5.48 12 .72 1.05 2.45.05 1.72-1-2.56-3.76-3.73-8.25-5.64-12.34a64.31 64.31 0 00-6.45-10.9 66.85 66.85 0 00-18.23-17c-1.1-.69-2.1 1-1 1.72zM138.83 273a292 292 0 00-6.15 41.07 85.43 85.43 0 00-.45 10.6c.13 2.67.5 5.6 2.1 7.83 1.35 1.88 3.59 2.94 5.83 2s3.57-3.34 4.7-5.37c3.39-6.09 5.92-12.62 9.4-18.66a1 1 0 00-1.72-1c-2.79 4.83-5 10-7.47 15a36.84 36.84 0 01-3.76 6.61 3.94 3.94 0 01-2.95 1.84 3.42 3.42 0 01-2.67-1.94c-1.43-2.38-1.51-5.47-1.49-8.16 0-3.14.29-6.29.54-9.41q.75-9.44 2.13-18.8 1.55-10.57 3.89-21c.27-1.26-1.65-1.79-1.93-.53zM234.94 305.3c-1.05 3.86-2.11 7.71-2.84 11.64a100.34 100.34 0 00-1.36 12 44.44 44.44 0 00.79 13.12 11.68 11.68 0 001.62 4.25 5.24 5.24 0 004.93 2.07c4-.69 6.07-5 7.43-8.39 1.54-3.82 2.55-7.87 3.74-11.81 1.36-4.46 2.76-8.92 4.14-13.38a55 55 0 015.32-12.63 68.64 68.64 0 014-5.75 23.6 23.6 0 014.71-5 75.51 75.51 0 0115.41-8.22c1.18-.48.66-2.42-.53-1.93a84.64 84.64 0 00-13 6.46 28.26 28.26 0 00-5 3.64 39.4 39.4 0 00-3.72 4.44 52.49 52.49 0 00-6.56 10.93c-1.71 4-2.88 8.29-4.19 12.47q-1.86 6-3.66 12c-1.12 3.76-2.11 7.63-4 11.1-.92 1.7-2.22 3.49-4.19 4s-3.37-.93-4-2.59a30.87 30.87 0 01-1.53-11.65 112 112 0 011.3-13.69c.73-4.24 1.87-8.4 3-12.54a1 1 0 00-1.93-.54z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <g id="cat_svg__body_three">
        <path
          className="cat_svg__cls-1"
          d="M141.27 279.56a70.3 70.3 0 007.24 25.08 36.62 36.62 0 0015.8 15.72 57.67 57.67 0 0021.24 5.7 68.43 68.43 0 0026.29-2.4 46.14 46.14 0 0021.74-14c.82-1-.58-2.4-1.42-1.42-10.52 12.49-27.19 16.71-43 16-14.34-.62-29.55-5.23-37.49-18.08-4.9-7.92-7.45-17.47-8.44-26.66-.14-1.27-2.14-1.28-2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M143.26 279.68l-1.7-5-.85-2.5c-.15-.43-.31-.86-.44-1.3 0-.1-.05-.21-.08-.31s0-.19 0 0c-.18.11-.2.14-.07.1a3.82 3.82 0 00.55-.11l.54-.09 1.11-.16a23.82 23.82 0 012.39-.2 10.26 10.26 0 014.52.71 1 1 0 001.37-.36 1 1 0 00-.36-1.37 12.63 12.63 0 00-5.63-1 26.82 26.82 0 00-2.83.27 7.72 7.72 0 00-2.71.57 2.06 2.06 0 00-.71 2.49c.28 1 .64 1.9 1 2.84l2 6c.41 1.21 2.35.69 1.93-.53zM210.18 270.3a31.7 31.7 0 013.42-2.3c.58-.35 1.17-.68 1.77-1l.91-.45c.15-.07.54-.3.71-.24s.51.85.59 1.12a7 7 0 01.23 1.7c.07 1.47.06 2.94.09 4.4a1 1 0 002 0c0-1.66 0-3.35-.13-5-.12-1.44-.56-3.61-2.16-4.11a3.43 3.43 0 00-2.49.48c-.73.36-1.46.74-2.17 1.15a34.7 34.7 0 00-4.18 2.86 1 1 0 101.41 1.41zM233.24 310.24l3.37-4a1 1 0 000-1.42 1 1 0 00-1.41 0l-3.38 4a1 1 0 001.42 1.42zM282.83 283.21c12.31-5.14 25.41-7.86 38.11-11.81 13-4 25.45-9.38 37.84-14.88 11.47-5.09 23.19-10 33.74-16.88a65.38 65.38 0 0013.72-11.3c2.45-2.81 8-10.5 2.55-13-2.64-1.22-6-.55-8.74 0-3.49.77-6.85 2.05-10.34 2.8-5.06 1.1-11.89.68-14.64-4.44-.61-1.14-2.34-.13-1.73 1 2.17 4 6.67 5.71 11 5.91 5.62.27 10.82-2.16 16.2-3.35a23.25 23.25 0 014.54-.6c1.15 0 2.76 0 3.6.95s0 2.94-.46 4.05a19.44 19.44 0 01-2.17 3.68c-3.48 4.72-8.5 8.46-13.31 11.71-10 6.76-21.17 11.56-32.15 16.45-12.08 5.38-24.22 10.69-36.78 14.85-13.77 4.55-28.13 7.3-41.55 12.91-1.17.49-.66 2.43.53 1.93z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M142.09 269.05a93.29 93.29 0 0116.31-41.41 121.26 121.26 0 0133.15-31.89A161.31 161.31 0 01237 175.62a223.8 223.8 0 0124.54-5.29c8.59-1.39 17.22-2.62 25.9-3.27 17.23-1.3 34.74-.07 50.86 6.58 2.17.89 4.29 1.89 6.38 3 1.14.59 2.15-1.14 1-1.72-16-8.16-33.76-10.85-51.57-10.19a239.36 239.36 0 00-26.17 2.64c-8.6 1.27-17.17 2.74-25.62 4.83a170.49 170.49 0 00-47 18.9 127 127 0 00-35.4 31.19A97.7 97.7 0 00141 263.37c-.35 1.88-.63 3.78-.87 5.68-.16 1.27 1.84 1.26 2 0z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M344.67 176.59a64 64 0 0124.13 27.56c1.83 4 3 8.32 5.48 12 .72 1.05 2.45.05 1.72-1-2.56-3.76-3.73-8.25-5.64-12.34a64.31 64.31 0 00-6.45-10.9 66.85 66.85 0 00-18.23-17c-1.1-.69-2.1 1-1 1.72zM139 274.14c-2.5 11.91-3.53 24.3-7.65 35.8-2.19 6.1-7.28 12.62-5.74 19.44.78 3.5 4.63 4.19 7.7 3.66 3.67-.64 6.65-3.07 9.16-5.67a51.45 51.45 0 0011.12-17.57c.45-1.21-1.48-1.73-1.93-.53a48.14 48.14 0 01-8.58 14.5c-2.27 2.6-4.84 5.4-8.11 6.72a8.88 8.88 0 01-4.72.63A3.39 3.39 0 01128 330a5.51 5.51 0 01-.6-2.92c0-3.32 1.46-6.51 2.84-9.45a83.84 83.84 0 003.43-8.05 96.11 96.11 0 003.56-14.3c1.24-6.85 2.33-13.73 3.76-20.55.26-1.26-1.66-1.8-1.93-.54zM234.94 305.3c-2.37 9.24-4.7 18.5-8.3 27.34a43 43 0 00-2.15 6.11c-.42 1.81-1 4.05-.42 5.88a6.66 6.66 0 004 3.83c2.16.89 4.45.25 6.43-.79 4.08-2.13 7-5.94 9.33-9.8a105.67 105.67 0 006.7-13.84c2-4.88 3.88-9.89 6.3-14.6a64.65 64.65 0 018.17-12.18c3.5-4.15 7.38-8.64 12.12-11.39 4.4-2.56 9.43-4 14.13-5.9 1.17-.48.66-2.41-.54-1.92-4 1.64-8.19 3-12.13 4.8-4.09 1.91-7.3 4.78-10.43 8a84.43 84.43 0 00-8.85 10.57 69.44 69.44 0 00-6.81 12.59c-2 4.66-3.81 9.4-5.89 14a61.25 61.25 0 01-7.1 12.6c-2.42 3.09-7.37 8.06-11.6 5.52a4.1 4.1 0 01-2-2.23 6.87 6.87 0 01.12-3 28.3 28.3 0 011.78-6c4-9.39 6.49-19.22 9-29.07a1 1 0 00-1.93-.54z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <g id="cat_svg__face">
        <path
          className="cat_svg__cls-1"
          d="M147.36 299.81s10.86-8.7 21-.92c0 0 7.11 12.89-14.93 11.07-.03.04-5.27-8.28-6.07-10.15zM195.67 302.47s-1.34-6.91 11.55-8.25c0 0 11.68-1.11 13.34 5.56 0 0 1.77 7.77-11.08 10.05 0 0-10.92 1.7-13.81-7.36zM182.24 304.87h2.9a.62.62 0 01.56.9l-1.45 2.93a.63.63 0 01-1.12 0l-1.46-2.93a.63.63 0 01.57-.9z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M182.82 308l-1.8 3.54a1 1 0 101.72 1l1.81-3.54a1 1 0 00-1.73-1z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M183.08 308.7l2 3.78a1 1 0 101.72-1l-2-3.78a1 1 0 00-1.37-.36 1 1 0 00-.36 1.37z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
      <path
        className="cat_svg__cls-1"
        d="M325.05 168.57c6.42-3.9 13.5-8.44 15.38-16.24a12.67 12.67 0 00-1.54-10.17 16 16 0 00-8.85-5.84c-7.94-2.33-16.15-.61-24.12.57a52.39 52.39 0 01-12.44.65 32.85 32.85 0 01-10.85-3 24.48 24.48 0 01-7.74-5.71 39.72 39.72 0 01-5.28-8.08c-2.42-4.49-3.81-9.29-5.22-14.18a51.06 51.06 0 01-1.8-8.4c-.36-3.06-.59-6.15-.68-9.24a52.18 52.18 0 012.93-17.72 35.21 35.21 0 019.21-14.69 39.68 39.68 0 0117.28-9.29 21.19 21.19 0 0111-.18 7.47 7.47 0 014.53 2.83 4.89 4.89 0 01.25 4.52c-1.72 3.84-6.12 2.31-9.41 2.59a24.46 24.46 0 00-9.79 3.36 27.28 27.28 0 00-13.12 16.52c-2.08 7.74-1.72 15.63-.4 23.46a55.67 55.67 0 003.05 10.88 25.84 25.84 0 005.35 9c3.75 3.79 8.71 4.75 13.78 5.42a52.44 52.44 0 009.52.87 111.18 111.18 0 0011.63-1.55 39.61 39.61 0 0112.62-.31 31.66 31.66 0 0110.65 4c6.07 3.59 11.13 9.27 12.5 16.35 1.29 6.69-1.42 13.21-5.69 18.28a47.22 47.22 0 01-9.06 8.11c-1.05.73-.05 2.47 1 1.73 6.16-4.34 11.94-9.84 14.66-17a21.85 21.85 0 00-1.75-18.82c-3.63-6.16-9.76-10.58-16.38-13.07a30.8 30.8 0 00-11.91-1.94 98.8 98.8 0 00-12.1 1.53 37.43 37.43 0 01-12.13.34c-2.47-.42-5-.63-7.45-1.12a16.84 16.84 0 01-7-2.89 19.93 19.93 0 01-6-8.6 61.23 61.23 0 01-4.48-23.12 42.43 42.43 0 011.94-12.55A22.65 22.65 0 01283 66.5a28.35 28.35 0 019.57-6.22 18.62 18.62 0 015.29-1.34c1.84-.14 3.68.2 5.52.05a6.54 6.54 0 006.1-5.9c.39-3.4-1.76-6.05-4.76-7.35-6.6-2.86-14.58-1-20.83 1.92a39.8 39.8 0 00-14.78 11.25 41.85 41.85 0 00-7.53 16.42 64.79 64.79 0 00-1.58 9.78 53.09 53.09 0 00.18 8.17 82 82 0 001.05 9 87.75 87.75 0 002.62 9.39 62.92 62.92 0 005.88 13.3 28 28 0 0014.86 12.51 35.18 35.18 0 0011.69 2.22 72.82 72.82 0 0012.32-1.19c8-1.24 17.31-2.9 24.77 1.34a10.24 10.24 0 015.53 9.41 16 16 0 01-5 10.51 49.72 49.72 0 01-9.81 7.09c-1.1.67-.09 2.4 1 1.73z"
        transform="translate(-13.14 -30.4)"
        id="cat_svg__tail"
      />
      <g id="cat_svg__lamp">
        <path
          className="cat_svg__cls-1"
          d="M340.33 73.33c-.89-1.43 19.58-17.5 24.95-21.67a11.87 11.87 0 012.72-1.61l11.06-4.58a1.38 1.38 0 00.59-.45l8-10.53a7.62 7.62 0 017.43-2.92c5.5 1 14.54 3.51 20.6 10.26a7.23 7.23 0 011.43 7.06l-.76 2.37a1.43 1.43 0 00.42 1.53l83.07 72.64a1.43 1.43 0 01.45 1.42l-23.88 99.87a1.44 1.44 0 001.11 1.75c4.54.9 18.47 4.68 25.51 18.07a6.12 6.12 0 01-2.86 8.39c-10.74 5-36.61 13.86-63.75.66a6.3 6.3 0 01-2.36-9.36c5.22-7.16 15.82-18.22 31.52-18.45a1.45 1.45 0 001.4-1.1l23.09-95.84a1.44 1.44 0 00-.45-1.42L417 65a1.44 1.44 0 00-2.37 1.34l1.63 8.7a10.15 10.15 0 01.14 2.75l-2.4 29.78a1.45 1.45 0 01-1.33 1.32c-7.67.58-49.1 1.86-72.34-35.56z"
          transform="translate(-13.14 -30.4)"
        />
        <circle className="cat_svg__cls-1" cx={480.86} cy={98.49} r={7.33} />
        <path
          className="cat_svg__cls-1"
          d="M610.4 339.39v3.83a1 1 0 001 1 1 1 0 001-1v-3.83a1 1 0 00-2 0zM615.62 335.89v4a1 1 0 002 0v-4a1 1 0 00-1-1 1 1 0 00-1 1zM615 345.78a1 1 0 000-2 1 1 0 000 2z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          d="M604.38 281.21a13.21 13.21 0 00-7.11 11.89s.49 7.85 11 .94c0 0 9.14-5.46 4.24-12-.01 0-2.26-4.38-8.13-.83z"
          fill="none"
          stroke="#1e212b"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          className="cat_svg__cls-1"
          d="M407.17 37.23l1.7-3.81a1.53 1.53 0 00.23-.75 1.52 1.52 0 000-.79 2.06 2.06 0 00-.92-1.2 2 2 0 00-1.54-.2 1.93 1.93 0 00-1.2.92l-1.7 3.81a2.18 2.18 0 00-.2 1.54 2 2 0 002.46 1.4 2 2 0 001.2-.92zM489.58 251.68c1.09 4.11 4.66 7 8.66 8.14 4.69 1.3 9.62.13 14.26-.79s9.36-1.89 14-3.21a98.84 98.84 0 0013.2-5c7.71-3.48 15.54-7.65 24.13-8.28a24.51 24.51 0 0111.3 1.46c3.4 1.42 5.84 4 8.44 6.52 3 2.89 6.29 5.55 10.48 6.29 4.83.85 9.82-.15 14.49-1.42 8-2.18 16.46-6.13 24.91-4.34 7.79 1.65 12.56 9.08 15.65 15.87s4.41 14.58 2.95 21.72a33.16 33.16 0 01-3.95 9.9 32.81 32.81 0 01-5.14 7.11c-.48.5-1 1-1.49 1.43l-.73.62-.32.26c.43-.34-.06 0-.15.11a27.71 27.71 0 01-3.62 2.2l-.88.42-.39.17h-.07c-.73.29-1.46.55-2.21.79a42.15 42.15 0 01-4.59 1.14 48 48 0 01-4.81.66 3.08 3.08 0 00-3 3 3 3 0 003 3c6.64-.63 13.46-2 19-5.84s9.24-9.28 12.17-15.14c7.55-15.13 2.72-34.32-8.38-46.45a21.72 21.72 0 00-11.29-6.74 33.46 33.46 0 00-13.52.14c-4.58.88-9 2.49-13.46 3.78-4.06 1.17-8.83 2.59-13.07 1.71-3.77-.78-6.31-3.62-9-6.27a27.68 27.68 0 00-10.54-6.85c-8.25-2.79-17.41-1.22-25.35 1.78-8.9 3.35-17.14 8.1-26.34 10.67-4.68 1.3-9.44 2.25-14.2 3.18-3.83.76-8.41 1.76-11.83-.24a5.12 5.12 0 01-2.55-3.15c-1-3.73-6.78-2.14-5.79 1.6z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-2"
          d="M491.51 251.15a10 10 0 007.27 6.74c4.32 1.2 8.91.06 13.19-.79 4.68-.92 9.36-1.88 13.94-3.21a94.45 94.45 0 0012.73-4.84c8.06-3.64 16.16-7.89 25.14-8.55a26.55 26.55 0 0111.88 1.59c3.76 1.51 6.48 4.27 9.32 7s5.73 5.11 9.6 5.78c4.46.77 9.13-.25 13.42-1.42 8.4-2.29 17.1-6.22 26-4.34 8.3 1.76 13.54 9.52 16.85 16.79s4.74 15.47 3.15 23.26a35.49 35.49 0 01-4.15 10.38 36.67 36.67 0 01-5.45 7.51c-4.15 4.29-9.54 6.58-15.33 7.68-1.77.34-3.56.57-5.35.74-1.27.12-1.28 2.12 0 2 6.3-.6 12.74-1.91 18-5.57s8.69-8.88 11.45-14.42c7.13-14.29 2.39-32.59-8.06-44a20 20 0 00-10.42-6.22 30.72 30.72 0 00-12.45.14c-4.58.88-9 2.49-13.46 3.78s-9.49 2.66-14.14 1.71c-4.13-.85-7-4-9.89-6.78a25.58 25.58 0 00-9.66-6.35c-7.93-2.68-16.66-1.09-24.29 1.79-8.9 3.35-17.14 8.1-26.34 10.67-4.67 1.3-9.44 2.25-14.19 3.18-4.36.86-9.31 1.94-13.38-.45a7.14 7.14 0 01-3.47-4.33 1 1 0 00-1.93.53z"
          transform="translate(-13.14 -30.4)"
        />
        <path
          className="cat_svg__cls-1"
          d="M611.41 323.5a12.45 12.45 0 014.42-9.58c1.69-1.43 4.36-3 6.58-2.64a1 1 0 001.13-1.47c-3.65-5.79-10.75 0-13.66 3.48a14 14 0 00-3.33 8.11c-.18 2.64.28 6.36 2.66 8 2.85 1.94 6.52-.24 8.86-2 .82-.61.38-2.29-.77-1.83-1.58.63-3.94 1.36-5.19-.26a3.24 3.24 0 01-.58-1.23 5.78 5.78 0 01-.12-.57c0-.14 0 .26 0 0-.09-1.28-2.09-1.29-2 0a5.59 5.59 0 001.37 3.31c1.83 2 4.77 1.58 7.05.68l-.77-1.83c-1.84 1.38-5.67 4-7.47 1.27-1.59-2.43-1.29-6.15-.35-8.75a16.56 16.56 0 015.65-6.95c1.92-1.47 5.26-3.05 6.92-.41l1.13-1.47c-3-.54-6.12 1.17-8.41 3.06a14.39 14.39 0 00-5.12 11.09 1 1 0 002-.01z"
          transform="translate(-13.14 -30.4)"
        />
      </g>
    </svg>
  );
}

export default SvgCat;
