import * as React from "react";

function SvgLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 534.56 397.09"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style>
          {".logo_svg__cls-1{fill:#1e212b}.logo_svg__cls-4{fill:#fff5c8}"}
        </style>
      </defs>
      <g id="logo_svg__Layer_1" data-name="Layer 1">
        <g id="logo_svg__Layer_6" data-name="Layer 6">
          <path
            className="logo_svg__cls-1"
            d="M206.87 112.38s34 33.82 43 83.7c0 0 18.92 73.72 2.57 110.1l19.76 17.16s24.07-44.88 2.51-127c0 0-14.8-72.29-58.24-102.94z"
          />
          <path
            fill="#fcdce1"
            d="M125.57 290.05l-4.17 21.83-52.89 15.71-14.48-18.47 71.54-19.07z"
          />
          <path
            className="logo_svg__cls-1"
            d="M119.28 330.66l12.01 26.28 2.97-4.21-8.29-20.05-6.69-2.02z"
          />
          <text
            transform="rotate(-14 1042.66 -1081.84)"
            style={{
              isolation: "isolate",
            }}
            fontSize={81.77}
            fontFamily="Sarabun-MediumItalic,Sarabun"
            fontWeight={500}
            fontStyle="italic"
            fill="#1e212b"
          >
            {"TW2"}
          </text>
          <path
            className="logo_svg__cls-1"
            d="M74.92 220.25c.49-.12 32.11-7.15 32.11-7.15l3.54 18.41-35.65 10.32z"
          />
        </g>
        <g id="logo_svg__Layer_5" data-name="Layer 5">
          <path
            className="logo_svg__cls-1"
            d="M69.73 329.68c10.65-2 21.25-4.25 31.74-7a136.88 136.88 0 0014.84-4.33 17.46 17.46 0 005.76-3.39 13.63 13.63 0 003.54-6.54 76 76 0 002.46-18.32c.06-3.22-4.94-3.22-5 0a74.26 74.26 0 01-1.79 15c-.47 2-.9 4.32-2.36 5.93a11.32 11.32 0 01-4.66 2.77c-4.6 1.64-9.41 2.83-14.12 4.06q-7.36 1.89-14.76 3.52-8.38 1.86-16.93 3.42a2.52 2.52 0 00-1.74 3.08 2.55 2.55 0 003.02 1.8z"
          />
          <path
            className="logo_svg__cls-1"
            d="M67.38 329.54A338.7 338.7 0 0194.77 367c4 6.41 7.71 13 11.5 19.57a22.31 22.31 0 006.87 7.89 18.37 18.37 0 009.24 2.65c7.45.35 14.73-2.28 22.19-1.71a2.5 2.5 0 100-5c-6.52-.49-12.9 1.4-19.39 1.7a17 17 0 01-8.27-1.24c-2.85-1.41-4.66-4-6.22-6.64-3.7-6.33-7.21-12.75-11.11-19q-6.08-9.68-12.79-18.92-7.56-10.47-15.87-20.3a2.5 2.5 0 00-3.54 3.54z"
          />
          <path
            className="logo_svg__cls-1"
            d="M111.73 318.59q11.56 29.78 24.25 59.09 3.6 8.32 7.29 16.61a2.51 2.51 0 003.42.9 2.56 2.56 0 00.9-3.42q-12.78-28.71-24.47-57.88-3.33-8.31-6.57-16.65a2.57 2.57 0 00-3.08-1.75 2.52 2.52 0 00-1.74 3.08z"
          />
          <path
            className="logo_svg__cls-1"
            d="M120 333.08c4.58-1.47 6.72 5.46 8 8.51 1.08 2.66 2.25 5.3 3.22 8 .44 1.25 1.43 4.36-.5 4.94-3.08.93-1.77 5.76 1.33 4.82 3.44-1 5.12-4.56 4.73-8a21.13 21.13 0 00-1.78-5.55l-2.25-5.56c-1.32-3.25-2.48-6.78-5-9.35a9.16 9.16 0 00-9.12-2.66c-3 1-1.75 5.81 1.33 4.83zM115.21 188.48a167.85 167.85 0 0049.5-25.88A175.48 175.48 0 00203.6 122a159.1 159.1 0 0015.05-27.34 2.58 2.58 0 00-.9-3.42 2.53 2.53 0 00-3.42.9 161.12 161.12 0 01-29.61 45.56 170.53 170.53 0 01-43 33.83 157.8 157.8 0 01-27.84 12.13c-3 1-1.74 5.81 1.33 4.82zM137.38 286c19.52-3.36 39.17-.14 58.3 4 18.2 3.91 37 7.12 53.22 16.81a85.94 85.94 0 0121.6 18.34 2.5 2.5 0 103.53-3.53c-11.59-13.73-27.22-23.42-44.33-28.69-9.62-3-19.55-4.88-29.37-7a297.56 297.56 0 00-30.09-5.38 120.53 120.53 0 00-34.19.68 2.51 2.51 0 00-1.74 3.07 2.55 2.55 0 003.07 1.75z"
          />
          <path
            className="logo_svg__cls-1"
            d="M274.38 324.6c7.64-14.51 11.16-30.67 12.06-47 .92-16.5-.76-33-3.2-49.35-2.63-17.59-6.08-35.12-11.32-52.14A208.72 208.72 0 00252 130.47 138.83 138.83 0 00222.38 95c-1.52-1.28-3.08-2.52-4.66-3.73a2.5 2.5 0 00-2.52 4.32 129.15 129.15 0 0129.8 33.09 194.08 194.08 0 0120.21 43 325.49 325.49 0 0112.16 51.08c2.61 15.93 4.57 32 4.33 48.23-.23 15.57-2.59 31.4-9 45.72-.81 1.81-1.69 3.6-2.61 5.36-1.5 2.85 2.81 5.38 4.31 2.53z"
          />
        </g>
        <g id="logo_svg__Layer_7" data-name="Layer 7">
          <path
            className="logo_svg__cls-1"
            d="M66.65 224.9l20.61-5.16 10.12-2.52 5-1.26 2.64-.66a3.18 3.18 0 00.51-.13c.17 0-.13-.37 0 0a46.75 46.75 0 012.34 9.17 39.44 39.44 0 01.63 4.32c.09 1.35-.08 1.89-1.34 2.46a32.06 32.06 0 01-3.82 1.23q-2.61.79-5.24 1.51c-3.28.9-6.59 1.73-9.9 2.52-7.26 1.72-14.56 3.26-21.83 5-3.13.73-1.8 5.56 1.33 4.82 7.27-1.7 14.57-3.23 21.83-5 3.54-.84 7.08-1.73 10.59-2.71q2.73-.75 5.45-1.59a21.9 21.9 0 004.76-1.8 6.38 6.38 0 002.94-3.72c.54-2 .09-4.1-.2-6.07a69.42 69.42 0 00-2.37-10.46c-.52-1.65-1.18-3.35-2.8-4.21-1.82-.95-3.82-.31-5.68.16l-11.49 2.88-25.41 6.4c-3.12.78-1.8 5.6 1.33 4.82z"
          />
          <path
            className="logo_svg__cls-1"
            d="M20 211.59a614.43 614.43 0 0184.86-22.09c2.67-.5 5.63-1.28 8.35-.72s3.69 3 4.56 5.53q3.38 9.67 6.33 19.47a364.85 364.85 0 019.94 41q.88 5 1.58 10a79.52 79.52 0 011.1 9.86 10.61 10.61 0 01-2.26 6.72c-1.82 2.29-4.34 3.26-7 4.29a297 297 0 01-39.6 11.86c-6.86 1.62-13.79 3.08-20.72 4.49l-10.08 2c-3.13.6-6.41 1.55-9.6 1.74-2.28.13-4.79-1-6.81-2a9.09 9.09 0 01-1.35-.78c.25.18-.37-.35-.34-.31-.48-.53.15.31-.15-.27-.89-1.73-1.6-3.57-2.37-5.36q-4-9.22-7.2-18.74a362.25 362.25 0 01-10-36.19c-1.21-5.29-2.55-10.58-3.46-15.93a35 35 0 01-.57-6.54 11.49 11.49 0 011.17-5.25 5.56 5.56 0 013.6-2.76c3.07-.92 1.76-5.74-1.33-4.82-11 3.27-8.61 16.78-6.74 25.32 2.77 12.65 5.64 25.29 9.36 37.7q2.91 9.75 6.56 19.25 2 5.28 4.27 10.47c1.31 3 2.19 6.27 5.17 8.06a23.34 23.34 0 003.56 1.63 23.17 23.17 0 003.86 1.33c3.53.69 7.33-.51 10.79-1.18 7.11-1.36 14.22-2.75 21.3-4.24a377 377 0 0042.6-11.21q5.18-1.75 10.28-3.74c3-1.2 5.9-2.45 8.11-5a15.67 15.67 0 003.76-8.06c.49-3.4-.2-6.91-.63-10.28a323.86 323.86 0 00-8.81-43.28q-2.88-10.62-6.3-21.09c-1.1-3.38-2.23-6.75-3.42-10.11s-2.61-6.29-5.9-7.74c-2.9-1.28-6.06-1.08-9.11-.62-3.66.54-7.3 1.32-10.93 2q-11.36 2.24-22.62 4.91-22.53 5.34-44.6 12.36-5.3 1.68-10.54 3.46c-3 1-1.73 5.85 1.33 4.82z"
          />
          <path
            className="logo_svg__cls-1"
            d="M51.87 310.38l4.27 7c1.26 2.06 2.45 4.2 3.88 6.15 2.7 3.69 6.4 6.92 11.29 6a2.51 2.51 0 10-1.31-4.85c-.76.15.23 0-.2 0h-.6c-.6 0 .3.13-.27 0-.15-.05-.31-.08-.46-.12s-.7-.3-.28-.1c-.19-.09-.37-.18-.54-.28a5.69 5.69 0 01-.52-.33s-.53-.39-.36-.25a16.21 16.21 0 01-3.18-3.68c-2.6-3.92-4.93-8-7.38-12.05a2.5 2.5 0 10-4.32 2.52zM10.7 225.18c-.28 2.1-1.55 3.31-3.21 4.75a35.11 35.11 0 00-4.89 4.55c-4.09 5.18-2.5 11.7-1 17.55q2.51 9.86 5.66 19.53 1.59 4.89 3.34 9.73c1.15 3.15 2.24 6.37 3.64 9.42a10 10 0 003.21 4 19.83 19.83 0 004.74 2.2c3.27 1.24 6.55 2.44 9.81 3.68a2.52 2.52 0 003.08-1.75 2.56 2.56 0 00-1.75-3.07l-8.41-3.16c-2.38-.89-4.89-1.48-6.11-3.9a76.67 76.67 0 01-3.3-8.53q-1.63-4.5-3.13-9-3-9.09-5.42-18.36c-1.16-4.42-3.71-10.9-.62-15 3-3.92 8.46-5.84 9.19-11.26a2.59 2.59 0 00-1.75-3.08c-1.16-.32-2.89.4-3.07 1.75z"
          />
        </g>
      </g>
      <g id="logo_svg__bolt_top">
        <path
          className="logo_svg__cls-4"
          d="M280.59 111.92C284.33 109 399.41 58.2 399.41 58.2l-30.35-3.49 91.2-49s-102.6 27.92-131.88 48.6l-4.74 3.51 24.53 5.72-73.42 53.15z"
        />
        <path
          className="logo_svg__cls-1"
          d="M276 119.43l48.6-22.76 48.55-22.8 27.55-12.93a2.52 2.52 0 001.18-2.82 2.58 2.58 0 00-2.41-1.84q-17.91-1.28-35.88-1.49l1.26 4.66Q405.3 37.79 446 16.56q11.4-6 22.89-11.89c2.53-1.31.77-5.34-1.93-4.57q-29.64 8.4-58.71 18.65T350.88 41c-10 4.21-20.05 8.39-28.46 15.35-1.47 1.21-.61 3.75 1.1 4.18l26.36 6.64-.6-4.57a626.55 626.55 0 00-59.77 40q-8.34 6.33-16.46 12.9a2.54 2.54 0 000 3.54 2.57 2.57 0 003.54 0 610.08 610.08 0 0157.69-41.43q8.66-5.49 17.48-10.7a2.52 2.52 0 00-.59-4.56l-26.37-6.68 1.08 4.2c7.17-5.93 15.89-9.64 24.37-13.26q13.92-6 28-11.45 28.25-11 57.14-20.32 16.32-5.25 32.81-9.93L466.27.34Q425.53 21.43 385 43q-11.38 6-22.76 12.14c-2.24 1.2-1.14 4.63 1.26 4.66q18 .24 35.88 1.49l-1.21-4.61-48.55 22.73-48.55 22.78-27.55 12.92a2.5 2.5 0 002.52 4.32z"
        />
      </g>
      <g id="logo_svg__bolt_bottom">
        <path
          className="logo_svg__cls-4"
          d="M395.36 298.75l29.83-12L319 267.8c82-4.64 147.17 5.36 147.17 5.36l-22.65 15c21.62 3.76 85 23 88.85 24.5z"
        />
        <path
          className="logo_svg__cls-1"
          d="M532 315.73c3.2.33 3.53-4.66.34-5L502 307.67l-53.35-5.49-53.36-5.42.91 4.75q16.48-7.11 33.35-13.29a2.52 2.52 0 001.38-2.69 2.62 2.62 0 00-2.29-2q-12.78-2-25.6-3.94-45.35-6.9-90.72-13.34l.3 4.95q16.8-1 33.6-1.45 29.83-.75 59.68.22c10 .35 20.06.83 29.93 1.48s19.86 1.18 29.25 4.63l-.77-4.21-22.69 15a2.47 2.47 0 00-.73 2.68 2.55 2.55 0 002.18 1.72q9.9 2 19.71 4.25a618 618 0 0168.48 20.12c3.05 1.08 4.7-3.64 1.66-4.71q-9.8-3.51-19.71-6.7a633.32 633.32 0 00-69.81-18l1.45 4.4 22.68-15c1.66-1.1.83-3.71-.79-4.28-10.74-4-22.36-4.4-33.66-5.1q-30.15-1.9-60.62-1.85t-60.8 1.92a2.48 2.48 0 00-2 2.62 2.52 2.52 0 002.36 2.35l25.64 3.67q45.35 6.54 90.63 13.59l-.9-4.75q-16.87 6.18-33.37 13.29c-2.37 1-1.47 4.51.92 4.75l30.3 3.09 53.33 5.42L532 315.7z"
        />
      </g>
    </svg>
  );
}

export default SvgLogo;
